import './CsSkill.css'
export default function CsSkill(props){
    return(
        <div className = 'skill'>
            
            <a target="_blank" rel="noreferrer" href="https://docs.google.com/document/d/1yQg2wu5zDGIzJgYdkwDvIu7-NzFKSpKlxBQIPne-xyk/edit?usp=sharing">
                <div>
                    <img src='document_art.png' height='500'></img>
                </div>
            </a>
        </div>
    )
}